/* styles.css */
.pulsate {
    animation: pulsate 1s infinite;
  }
  
  @keyframes pulsate {
    0% {
      box-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    }
    50% {
      box-shadow: 0 0 15px rgba(255, 0, 0, 0.7);
    }
    100% {
      box-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    }
  }
  